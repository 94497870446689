<template>
  <div>
    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif">
      <template #header>
        <span>{{$t('notificationTypes')}}
        </span>
      </template>
      <div class="mt-2">
        <div class="button-switch">
          <span class="text-bold-checkbox">{{$t('emailNotification')}}</span>
          <b-form-checkbox switch v-model="dataSetting.enabled" id="enabled"></b-form-checkbox>
        </div>
        <span class="card-subtitle-preference">{{$t('mesageImportEmail')}}</span>
          <span class="text-bold-checkbox">{{ email_user }}</span>

        <div class="button-switch borderSMS">
          <span class="text-bold-checkbox">{{$t('pushNotification')}}</span>
          <b-form-checkbox v-model="enabled_push" switch id="enabled_push"></b-form-checkbox>
        </div>
        <span class="card-subtitle-preference">{{$t('messagePush')}}</span>
      </div>
    </b-card>

    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif">
      <template #header>
        <span>{{$t('account')}}</span>
        <span>{{$t('email')}}</span>
      </template>
      <div class="mt-2">
        <b-form-group>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('updateEmail')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="update_email"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('updatePassNotification')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="update_pass_notification"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('unreadMessage')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.unread_messages" id="unread_messages"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('forgotPassNotification')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="forgot_password_notification"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('signUpNotification')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="sing_up_notification"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('resendVerification')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="resend_verification"></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </b-card>

    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif">
      <template #header>
        <span>{{$t('financeTitle')}}</span>
        <span>{{$t('email')}}</span>
      </template>
      <div class="mt-2">
        <b-form-group>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('paymentRefunded')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="payment_refunded"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('paymentReleased')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="payment_relased"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('paymentFailed')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="payment_failed"></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </b-card>

    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif">
      <template #header>
        <span>{{$t('listingTitle')}}</span>
        <span>{{$t('email')}}</span>
      </template>
      <div class="mt-2">
        <b-form-group>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('listingChangeReq')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="listing_change_request"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('listingDecision')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.listing_decision" id="listing_decision"></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </b-card>

    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif">
      <template #header>
        <span>{{$t('castingCallTitle')}}</span>
        <span>{{$t('email')}}</span>
      </template>
      <div class="mt-2">
        <b-form-group>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('castingCallPitched')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.casting_call_pitched" id="casting_call_pitched"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('castingCallPitch')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.casting_call_decision" id="casting_call_decision"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('pitchRequest')}}</span>
            </div>
            <b-form-checkbox id="casting_call_decision"></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </b-card>

    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif">
      <template #header>
        <span>{{$t('orderTitle')}}</span>
        <span>{{$t('email')}}</span>
      </template>
      <div class="mt-2">
        <b-form-group>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderNew')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="order_new"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderSellDecision')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.order_seller_decision" id="order_seller_decision"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderSellInactive')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.oser_seller_inactive" id="oser_seller_inactive"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderPending')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.order_pending_review" id="order_pending_review"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderExpired')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.order_expired" id="order_expired"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderCancelled')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.order_cancelled" id="order_cancelled"></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </b-card>

    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif">
      <template #header>
        <span>{{$t('orderAddTitle')}} </span>
        <span>{{$t('email')}}</span>
      </template>
      <div class="mt-2">
        <b-form-group>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderCUpdateReq')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.contract_update_requested" id="contract_update_requested"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderCUpdateDec')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.contract_update_decision" id="contract_update_decision"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderCUpdateRemin')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.contract_update_reminder" id="contract_update_reminder"></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </b-card>

    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif" v-if="!is_brand">
      <template #header>
        <span>{{$t('orderDeliverTitle')}}</span>
        <span>{{$t('email')}}</span>
      </template>
      <div class="mt-2">
        <b-form-group>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('deliveryInbound')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.delivery_inbound" id="delivery_inbound"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('deliveryDecision')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.delivery_decision" id="delivery_decision"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('deliveryDue')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.devilery_due_soon" id="devilery_due_soon"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('deliveryExpired')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.delivery_expired" id="delivery_expired"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('deliveryReminder')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.delivery_reminder" id="delivery_reminder"></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </b-card>

    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif">
      <template #header>
        <span>{{$t('orderIssuesTitle')}}</span>
        <span>{{$t('email')}}</span>
      </template>
      <div class="mt-2">
        <b-form-group>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('customerAssitance')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.customer_asistance_requested" id="customer_asistance_requested"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderCancellation')}}</span>
            </div>
            <b-form-checkbox disabled v-model="defaultValue" id="order_cancellation"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('orderCancellationReq')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.order_cancelation_request" id="order_cancelation_request"></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </b-card>

    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif">
      <template #header>
        <span>{{$t('chatTitle')}}</span>
        <span>{{$t('email')}}</span>
      </template>
      <div class="mt-2">
        <b-form-group>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('sellerReady')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.seller_ready_chat" id="seller_ready_chat"></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('notifySeller')}}</span>
            </div>
            <b-form-checkbox v-model="dataSetting.seller_first_message" id="seller_first_message"></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </b-card>
    <b-card class="card-container-notification" body-class="border-card-info-notif" header-class="border-header-notif">
      <template #header>
        <span>{{$t('subscriptions')}} <b-icon icon="question-circle"></b-icon></span>
      </template>
      <div class="mt-2">
        <b-form-group>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('updatedPro')}}</span>
            </div>
            <b-form-checkbox></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('updatedProIa')}}</span>
            </div>
            <b-form-checkbox></b-form-checkbox>
          </div>
          <div class="button-checkbox">
            <div>
              <span class="text-bold-checkbox">{{$t('updatedProIaPlus')}}</span>
            </div>
            <b-form-checkbox></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BFormCheckbox, BFormGroup } from 'bootstrap-vue'
import service from '@/services/notifications.js';
import { getUserData } from '@/libs/utils/user';

export default {
  name: "settingNotification",
  components: {
    BCard,
    BFormCheckbox,
    BFormGroup,
  },

  data() {
    return {
      dataSetting: {},
      defaultValue: true,
      enabled_push: false,
      email_user: getUserData().email,
    }
  },

  created() {
    this.getData()
  },
  setup() {

    return {
    }
  },
  computed: {
    is_brand() {
      return getUserData() && getUserData().main_group.name !== 'creator'
    }
  },
  methods: {
    getData() {
      service.settingsNotifications().then(response => {
        this.dataSetting = response;
        this.enabled_push = this.dataSetting.enabled_push === 'allow_push'
      })
    },
    updateData() {
      service.updateSettingsNotifications(this.dataSetting).then((response) => {
        if (this.dataSetting === response) {
          this.dataSetting = response
        }
      })
    },
  },
  watch: {
    dataSetting: {
      handler(_, old_value) {
        if (Object.keys(old_value).length > 0) this.updateData()
      },
      deep: true
    },
  },
};
</script>

<style>
.card-container-notification {
  border-radius: 0.75rem !important;
}

.border-card-info-notif {
  border: 0.0625rem solid rgba(222, 226, 230, .7);
  border-top: none;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
}
.borderSMS {
  margin-top: 25px;
  border-top:#1d4b74;
}
.border-header-notif {
  border: 0.0625rem solid rgba(222, 226, 230, .7) !important;
  border-radius: 0.75rem 0.75rem 0rem 0rem !important;
}

.card-subtitle-preference {
  display: block;
  color: #6c757d;
  margin-top: 3px;
  margin-bottom: 7px;
}

.text-bold-checkbox {
  color: #1f2122
}

.link-update {
  margin-left: 3px;
  color: #55a6c4;
  cursor: text;
}

.link-email-requerid {
  margin-left: 3px;
  color: #c40b0b;
  cursor: text;
  font-size: .90rem;
}

.button-switch {
  display: flex;
  justify-content: space-between;
}
.button-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0 !important;
}
.button-checkbox:hover {
  background: #f8f9fa;
}
</style>